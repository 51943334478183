<template>
  <div class="relative z-0 h-full">
    <div class="flex h-full flex-col">
      <div class="border-b border-grid">
        <div class="flex items-center justify-between overflow-x-auto px-4">
          <div class="flex">
            <div class="flex space-x-3">
              <div
                v-if="fromCoin"
                @click="exchangeModel"
                :class="mode == 'icon-line-chart' || props.candleChartMode ? '' : 'cursor-pointer hover:text-white'"
                class="flex min-w-[7rem] items-center whitespace-nowrap py-2 text-xs text-gray-400"
              >
                <template v-if="mode == 'icon-candle-chart'">
                  {{ supportedSymbol }}
                  <span v-if="exchange">
                    &nbsp;- {{ exchange.name }} <span v-if="exchange.exchange"> ({{ exchange.exchange }})</span>
                  </span>
                  <span v-else-if="!exchange"> Select Exchange </span>
                </template>
                <template v-if="mode == 'icon-line-chart'"> {{ chartSymbol }} - Tie </template>
                <BaseTooltip v-if="showError" :position="props.candleChartMode ? 'bottom' : 'top'">
                  <template #target>
                    <ExclamationCircleIcon class="ml-1 h-4 w-4 text-red-400" />
                  </template>
                  <template #default>No data found</template>
                </BaseTooltip>
              </div>
              <div class="border-l border-grid" v-if="fromCoin"></div>
              <div class="py-2">
                <BaseMenu
                  v-if="!props.candleChartMode"
                  class="text-gray-400 hover:text-white"
                  :items="filteredMetrics"
                  portal
                  @action="handleIndicatorAction"
                >
                  <template #icon>
                    <div class="flex">
                      <span>
                        {{ (filteredMetrics?.find(metric => metric.id === selectedTag) || {}).title || 'Indicators' }}
                      </span>
                      <ChevronDownIcon class="ml-0 h-4 w-4 text-gray-400" />
                    </div>
                  </template>
                </BaseMenu>
                <div v-else class="text-xs text-gray-400">
                  {{ (filteredMetrics?.find(metric => metric.id === selectedTag) || {}).title || 'Indicators' }}
                </div>
              </div>
              <div class="border-l border-grid"></div>
              <div class="flex items-center space-x-2">
                <BaseMenu
                  class="text-gray-400 hover:text-white"
                  :items="filteredTimeFrames"
                  width-class="w-17"
                  @action="handleAction"
                  portal
                >
                  <template #icon>
                    <div class="flex">
                      <span>{{ timeframeSelected.label }}</span>
                      <ChevronDownIcon class="ml-0 h-4 w-4 text-gray-400" />
                    </div>
                  </template>
                </BaseMenu>
              </div>
              <div class="border-l border-grid"></div>
            </div>
          </div>
          <div class="flex">
            <div class="flex space-x-3">
              <div v-if="loading" class="flex items-center">
                <BaseLoader size="w-4 h-4" />
              </div>
              <div class="border-l border-grid"></div>
              <div class="py-2">
                <BaseMenu
                  v-if="!props.candleChartMode"
                  class="text-gray-400 hover:text-white"
                  :items="[
                    { id: 'icon-line-chart', label: 'Line', action: 'icon-line-chart' },
                    { id: 'icon-candle-chart', label: 'Candle', action: 'icon-candle-chart' }
                  ]"
                  width-class="w-20"
                  position="right"
                  @action="mode = $event"
                >
                  <template #icon>
                    <component :is="iconMap[mode].c" :icon="iconMap[mode].i" class="h-4 w-4" />
                  </template>
                </BaseMenu>
                <component v-else :is="iconMap[mode].c" :icon="iconMap[mode].i" class="h-4 w-4 text-gray-400" />
              </div>
              <div class="border-l border-grid"></div>
              <div class="flex">
                <BaseTooltip
                  v-if="updatedChartLink"
                  :position="props.candleChartMode ? 'bottom' : 'top'"
                  class="flex items-center"
                >
                  <template #target>
                    <a :href="updatedChartLink" class="text-gray-400 hover:text-white">
                      <ArrowTopRightOnSquareIcon class="h-4 w-4" />
                    </a>
                  </template>
                  <template #default>Go To Full TradingView</template>
                </BaseTooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative h-full w-full bg-gray-900">
        <div
          class="absolute bottom-0 right-0 z-40 mb-1 mr-2 flex items-center text-xs font-medium"
          style="font-size: 10px; color: rgb(89, 149, 280)"
          v-if="rightAxisLabel"
        >
          {{ rightAxisLabel }}
        </div>
        <div class="relative h-full" :id="id" />
      </div>
    </div>
    <div class="absolute inset-0 z-10 flex h-10 cursor-not-allowed items-center justify-center" v-if="loading"></div>
  </div>
  <ModalExchange
    v-if="fromCoin && !props.candleChartMode"
    :show="showExchangeModal"
    :exchanges="myExchanges"
    :pairs="updatedPairs"
    :all-exchanges="allExchanges"
    :uid="coinUids[0]"
    @close="showExchangeModal = false"
    @apply:pair="applyPair($event)"
  />
</template>

<script setup>
import { useStore } from 'vuex';
import { DateTime } from 'luxon';
import groupBy from 'lodash/groupBy';
import { CcxtApi } from '@/api/ccxtApi';
import { useLocalStorage } from '@vueuse/core';
import colors from 'tailwindcss/colors';
import { createChart, CrosshairMode } from 'lightweight-charts';
import ModalExchange from '@/components/modal/ModalExchange.vue';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { ArrowTopRightOnSquareIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import { getMinMove, getNumberPrecision } from '@/composeables/helpers';
import useHttp from '@/composeables/http';
import CustomIcons from '@/components/CustomIcons.vue';

const $http = useHttp();
const $store = useStore();
const $ccxtApi = new CcxtApi();
const emits = defineEmits(['update-timeframe', 'update-metric']);

//PROPS
const props = defineProps({
  coinUids: Array,
  metrics: Array,
  primaryMetric: { type: String, default: 'price' },
  rightAxisLabel: String,
  expandChartLink: String,
  chartSymbol: String,
  fromCoin: { type: Boolean, default: false },
  selectedCategory: Object,
  timeSelected: { type: String, default: null },
  metricSelected: { type: String, default: null },
  forWidget: { type: Boolean, default: false },
  candleChartMode: { type: Boolean, default: false }
});

const iconMap = {
  'icon-line-chart': { c: CustomIcons, i: 'line-chart' },
  'icon-candle-chart': { c: CustomIcons, i: 'candle-chart' }
};

//SERIES
const showExchangeModal = ref(false);
let priceLineSeries = undefined;
let metricSeries = undefined;

//API DATA
let apiData = undefined;

//CHART
const id = `coin_chart_${Math.random()}`;
let chart = undefined;
const dayTimeFrame = computed(() => {
  return ['30D', '90D', '6M', '1Y', 'All Time'].includes(selectedTimeframe.value);
});
const loading = ref(true);
const supportedSymbol = ref(null);
onMounted(() => {
  if (props.fromCoin) {
    getSymbol();
  }
  const elm = document.getElementById(id);
  // Account for some metrics not being supported across different charts (like overview vs. individual coin)
  chart = createChart(elm, {
    height: elm.clientHeight,
    handleScale: {
      mouseWheel: false,
      pinch: false
    },
    handleScroll: {
      mouseWheel: false,
      pressedMouseMove: false,
      horzTouchDrag: false,
      vertTouchDrag: false
    },
    rightPriceScale: {
      visible: true,
      borderColor: colors.slate['700']
    },
    leftPriceScale: {
      visible: false,
      borderColor: colors.slate['700']
    },
    watermark: {
      visible: true,
      fontSize: 20,
      horzAlign: 'center',
      vertAlign: 'center',
      color: 'rgba(255, 255, 255, 0.1)',
      text: 'The Tie Terminal'
    },
    layout: {
      backgroundColor: colors.slate['900'],
      lineColor: colors.slate['700'],
      textColor: colors.slate['400']
    },
    crosshair: {
      color: colors.slate['700']
    },
    grid: {
      vertLines: {
        color: 'rgba(255, 255, 255, 0.05)'
      },
      horzLines: {
        color: 'rgba(255, 255, 255, 0.05)'
      }
    },
    timeScale: {
      timeVisible: !dayTimeFrame.value,
      secondsVisible: false,
      lockVisibleTimeRangeOnResize: true,
      minBarSpacing: 0.001
    }
  });

  const timeScale = chart.timeScale();
  var timer = null;
  timeScale.subscribeVisibleLogicalRangeChange(() => {
    if (timer !== null) {
      return;
    }

    timer = setTimeout(() => {
      var logicalRange = timeScale.getVisibleLogicalRange();
      if (logicalRange !== null && priceLineSeries && mode.value == 'icon-line-chart') {
        const barsInfo = priceLineSeries.barsInLogicalRange(logicalRange);
        if (barsInfo.barsBefore < 0) {
          refreshData(new Date(barsInfo.from * 1000 + barsInfo.barsBefore * 1000 * 60 * 5), lastTimeScanned.value);
        }
      }
      timer = null;
    }, 500);
  });
  nextTick(() => {
    const timeframe = timeframes.find(f => {
      return f.id === selectedTimeframe.value;
    });
    if (priceLineSeries) {
      chart.removeSeries(priceLineSeries);
    }
    if (metricSeries) {
      chart.removeSeries(metricSeries);
    }
    priceLineSeries = undefined;
    metricSeries = undefined;
    if (mode.value == 'icon-line-chart') {
      let startDate = timeframe?.time ? new Date(new Date().getTime() - timeframe.time) : -1;
      refreshData(startDate, lastTimeScanned.value).then(() => {
        chart.timeScale().fitContent();
      });
    }
  });
});

//EXCHANGE
const exchange = ref(null);
const replotChart = ref(false);

const myExchanges = ref([]);
const getMyExchanges = async () => {
  myExchanges.value = await $ccxtApi.getMyExchanges();
};
const allExchanges = ref([]);
const getAllExchanges = async () => {
  allExchanges.value = await $ccxtApi.getAllExchanges();
};
const exchangesById = computed(() => {
  return groupBy(allExchanges.value, 'exchange');
});
async function applyPair(pair) {
  showExchangeModal.value = false;
  exchange.value = exchangesById.value[pair.exchange]?.[0];
  supportedSymbol.value = pair.pair;
  saveLocalStorage();
  replotChart.value = !replotChart.value;
}
function saveLocalStorage() {
  let exchangeStorage = useLocalStorage('exchanges', {});
  let pairStorage = useLocalStorage('exchange_pairs', {});
  exchangeStorage.value[`${props.chartSymbol}`] = exchange.value.exchange;
  pairStorage.value[`${props.chartSymbol}`] = supportedSymbol.value;
}
watch(
  () => replotChart.value,
  () => {
    const selectedFreq = timeframeSelected.value?.frequency;
    const availableFreq = exchangeTimeframes.value;
    if (!availableFreq.includes(selectedFreq)) {
      let freq = exchangeTimeframes.value && timeframes.map(i => i.frequency)[0];
      if (props.candleChartMode && exchangeTimeframes.value.some(x => ['6h', '1d'].includes(x))) {
        freq = timeframes.find(
          i => i.frequency == exchangeTimeframes.value.find(x => ['6h', '1d'].includes(x))
        )?.frequency;
      }
      handleAction(timeframes.find(i => i.frequency === freq)?.id);
    } else {
      const selectedTimeframeId = selectedTimeframe.value;
      const timeframe = timeframes.find(f => f.id === selectedTimeframeId);
      const startDate = timeframe?.time ? new Date(new Date().getTime() - timeframe.time) : -1;
      refreshData(startDate, lastTimeScanned.value);
    }
  }
);

//SYMBOL
function plotCandles() {
  supportedSymbol.value = props.chartSymbol.split(':')[1];
  exchange.value = { exchange: props.chartSymbol.split(':exchange:')[1] };
  mode.value = 'icon-candle-chart';
  replotChart.value = !replotChart.value;
}

async function getSymbol() {
  Promise.all([await getMyExchanges(), await getTopPairs(), await getAllExchanges()]).finally(() => {
    if (props.candleChartMode) {
      plotCandles();
      return;
    }
    let exchangesObj = groupBy(allExchanges.value, 'exchange');
    let pairs = groupBy(exchangePairs.value, 'exchange');
    let storageExchanges = JSON.parse(localStorage.getItem('exchanges')) || {};
    let storagePairs = JSON.parse(localStorage.getItem('exchange_pairs')) || {};
    if (
      storageExchanges[props.chartSymbol] &&
      exchangesObj[storageExchanges[props.chartSymbol]] &&
      exchangesObj[storageExchanges[props.chartSymbol]].length &&
      storagePairs[props.chartSymbol]
    ) {
      exchange.value = exchangesObj[storageExchanges[props.chartSymbol]]?.[0];
      supportedSymbol.value = storagePairs[props.chartSymbol];
      replotChart.value = !replotChart.value;
    } else {
      let data = exchangePairs.value.filter(i => myExchanges.value.map(j => j.exchange).includes(i.exchange));
      data = data.sort((a, b) => parseFloat(b.day_volume) - parseFloat(a.day_volume));
      if (data.length) {
        findSymbol(data, exchangesObj, pairs);
      }
      if (!supportedSymbol.value) {
        findSymbol(exchangePairs.value, exchangesObj, pairs);
      }
      if (!supportedSymbol.value) {
        loading.value = false;
        // eslint-disable-next-line no-console
        console.log('No Supported Symbol Found');
      }
    }
  });
}
function findSymbol(data, exchangesObj, pairs) {
  data.forEach(item => {
    if (!supportedSymbol.value) {
      if (
        exchangesObj[item.exchange] &&
        exchangesObj[item.exchange].length &&
        pairs[item.exchange] &&
        pairs[item.exchange].length
      ) {
        let symbol = $ccxtApi.getExchangeSymbol(pairs[item.exchange], props.chartSymbol);
        if (symbol) {
          exchange.value = exchangesObj[item.exchange]?.[0];
          supportedSymbol.value = symbol;
          saveLocalStorage();
          replotChart.value = !replotChart.value;
          return;
        }
      }
    }
  });
}
//TIMEFRAMES
const lastTimeScanned = ref(new Date());
const lastTimeCCXTScanned = ref(new Date(new Date().getTime() - 1000 * 60 * 60 * 6));
const mode = useLocalStorage('defaultChartMode', 'icon-line-chart');
const timeframes = [
  {
    time: 1000 * 5 * 60,
    id: '5m',
    action: '5m',
    label: '5m',
    frequency: '1m',
    seconds: 60,
    frequency_time: 1 * 60 * 1000
  },
  {
    time: 1000 * 15 * 60,
    id: '15m',
    action: '15m',
    label: '15m',
    frequency: '1m',
    seconds: 60,
    frequency_time: 1 * 60 * 1000
  },
  {
    time: 1000 * 30 * 60,
    id: '30m',
    action: '30m',
    label: '30m',
    frequency: '1m',
    seconds: 60,
    frequency_time: 1 * 60 * 1000
  },
  {
    time: 1000 * 60 * 60,
    id: '1H',
    action: '1H',
    label: '1h',
    frequency: '1m',
    seconds: 60,
    frequency_time: 1 * 60 * 1000
  },
  {
    time: 1000 * 60 * 60 * 3,
    id: '3H',
    action: '3H',
    label: '3h',
    frequency: '5m',
    seconds: 300,
    frequency_time: 5 * 60 * 1000
  },
  {
    time: 1000 * 60 * 60 * 6,
    id: '6H',
    action: '6H',
    label: '6h',
    frequency: '5m',
    seconds: 300,
    frequency_time: 5 * 60 * 1000
  },
  {
    time: 1000 * 60 * 60 * 24,
    id: '1D',
    action: '1D',
    label: '1d',
    frequency: '1h',
    seconds: 3600,
    frequency_time: 60 * 60 * 1000
  },
  {
    time: 1000 * 60 * 60 * 24 * 7,
    id: '7D',
    action: '7D',
    label: '7d',
    frequency: '6h',
    seconds: 3600 * 6,
    frequency_time: 60 * 60 * 1000 * 6
  },
  {
    time: 1000 * 60 * 60 * 24 * 30,
    id: '30D',
    action: '30D',
    label: '30d',
    frequency: '1d',
    seconds: 3600 * 24,
    frequency_time: 1000 * 60 * 60 * 24
  },
  {
    time: 1000 * 60 * 60 * 24 * 90,
    id: '90D',
    action: '90D',
    label: '3M',
    frequency: '1d',
    seconds: 3600 * 24,
    frequency_time: 1000 * 60 * 60 * 24
  },
  {
    time: 1000 * 60 * 60 * 24 * 180,
    id: '6M',
    action: '6M',
    label: '6M',
    frequency: '1d',
    seconds: 3600 * 24,
    frequency_time: 1000 * 60 * 60 * 24
  },
  {
    time: 1000 * 60 * 60 * 24 * 365,
    id: '1Y',
    action: '1Y',
    label: '1Y',
    frequency: '1d',
    seconds: 3600 * 24,
    frequency_time: 1000 * 60 * 60 * 24
  },
  {
    time: null,
    id: 'All Time',
    action: 'All Time',
    label: 'All Time',
    frequency: '1d',
    seconds: 3600 * 24,
    frequency_time: null
  }
];
const selectedTag = ref(props.metricSelected || localStorage.getItem('selectedTag') || 'none');
const selectedTimeframe = ref(props.timeSelected || localStorage.getItem('selectedTimeframe') || 'All Time');
const interval = ref(undefined);
watch(selectedTag, () => {
  if (apiData) {
    if (!props.fromCoin) {
      chart.removeSeries(metricSeries);
      metricSeries = undefined;
    }
    refreshData();
  }
});
async function refreshData(from, to) {
  clearInterval(interval.value);
  showError.value = false;
  if (mode.value == 'icon-candle-chart') {
    Promise.all([await refreshFromCCXT(from), await refreshFromAPI(from, to, false)]).finally(() => {
      chart.timeScale().fitContent();
      loading.value = false;
      if (timeframeSelected.value?.frequency_time) {
        interval.value = setInterval(() => {
          refreshFromCCXT(undefined, true);
        }, 60000);
      }
    });
  } else {
    Promise.all([await refreshFromAPI(from, to, true)]).finally(() => {
      chart.timeScale().fitContent();
      loading.value = false;
    });
  }
}
watch(mode, () => {
  localStorage.setItem('defaultChartMode', mode.value);
  const timeframe = timeframes.find(f => {
    return f.id === selectedTimeframe.value;
  });
  if (priceLineSeries) {
    chart.removeSeries(priceLineSeries);
    priceLineSeries = undefined;
  }
  let startDate = timeframe?.time ? new Date(new Date().getTime() - timeframe.time) : -1;
  refreshData(startDate, lastTimeScanned.value);
});
const showError = ref(false);
async function refreshFromCCXT(from, refresh) {
  let freq = timeframeSelected.value?.frequency ? timeframeSelected.value?.frequency : exchangeTimeframes.value[0];
  if (exchange.value && freq && from && supportedSymbol.value) {
    loading.value = true;
    if (priceLineSeries) {
      chart.removeSeries(priceLineSeries);
      priceLineSeries = undefined;
    }
    let response = [];
    let callAgain = true;
    let since = DateTime.fromJSDate(new Date(from)).toMillis();
    while (callAgain) {
      try {
        const res = await $ccxtApi.fetchOHLCV({
          exchange: exchange.value.exchange,
          pair: supportedSymbol.value,
          frequency: freq,
          since: since
        });
        if (res.length && res.length > 1) {
          const lastCandle = res[res.length - 1];
          const lastTimestamp = lastCandle[0];
          since = lastTimestamp + 1;
          response = response.concat(res);
        } else {
          callAgain = false;
          break;
        }
      } catch (e) {
        callAgain = false;
      }
    }
    if (response.length) {
      response = response.sort((a, b) => {
        const aTime = new Date(a[0]).getTime();
        const bTime = new Date(b[0]).getTime();

        return a[0] > b[0] ? 1 : -1;
      });
      let mapped = response.map(x => {
        lastTimeCCXTScanned.value = x[0];
        return {
          time: Math.round((new Date(x[0]).getTime() + (DateTime.local().offset / 60) * 60 * 60 * 1000) / 1000),
          open: x[1],
          high: x[2],
          low: x[3],
          close: x[4]
        };
      });
      chart.applyOptions({
        crosshair: {
          mode: CrosshairMode.Normal
        },
        rightPriceScale: {
          visible: true
        }
      });
      let precision = 2,
        minMove = 0.01;

      if (mapped && mapped[0] && mapped[0]?.open) {
        precision = getNumberPrecision(mapped[0].open);
        minMove = getMinMove(precision);
      }
      priceLineSeries = chart.addCandlestickSeries({
        upColor: '#26a69a',
        downColor: '#ef5350',
        borderVisible: false,
        wickUpColor: '#26a69a',
        wickDownColor: '#ef5350',
        priceFormat: {
          type: 'price',
          precision: precision,
          minMove: minMove
        }
      });
      priceLineSeries.setData(mapped);
    } else {
      showError.value = true;
      chart.applyOptions({
        rightPriceScale: {
          visible: false
        }
      });
    }
  } else if (exchange.value && freq && refresh) {
    let response = [];
    // updating latest datapoint after selected timeframe interval
    try {
      response = await $ccxtApi.fetchOHLCV({
        exchange: exchange.value.exchange,
        pair: supportedSymbol.value,
        frequency: freq,
        since: lastTimeCCXTScanned.value - timeframeSelected.value?.seconds
      });
    } catch (error) {
      response = [];
    }
    if (response.length && priceLineSeries) {
      showError.value = false;
      for (let i = 0; i < response.length; i++) {
        let latestData = response[i];
        lastTimeCCXTScanned.value = latestData[0];
        priceLineSeries.update({
          time: Math.round(
            (new Date(latestData[0]).getTime() + (DateTime.local().offset / 60) * 60 * 60 * 1000) / 1000
          ),
          open: latestData[1],
          high: latestData[2],
          low: latestData[3],
          close: latestData[4]
        });
      }
    }
  }
}

async function refreshFromAPI(from, to, priceSeries) {
  if (from && to) {
    const mapping = {};
    const items = [...(await coinHistory(props.coinUids, from, to)), ...(apiData || [])];
    const withoutDupes = [];
    items.map(point => {
      const key = Math.round(new Date(point.datetime).getTime() / 1000).toString();

      if (mapping[key]) {
        return;
      }

      withoutDupes.push(point);
      mapping[key] = key;
    });

    apiData = withoutDupes.sort((a, b) => {
      const aTime = new Date(a.datetime).getTime();
      const bTime = new Date(b.datetime).getTime();
      return aTime > bTime ? 1 : -1;
    });
  }
  if (priceSeries) {
    let correctValue =
      apiData?.[0]?.[props.primaryMetric] ?? apiData?.[apiData?.length - 1]?.[props.primaryMetric] ?? null;
    let precision = 2;
    let minMove = 0.01;

    if (correctValue) {
      precision = getNumberPrecision(correctValue);
      minMove = getMinMove(precision);
    }
    priceLineSeries =
      priceLineSeries ||
      chart.addAreaSeries({
        topColor: 'rgba(59, 130, 246, 0.5)',
        bottomColor: 'rgba(59, 130, 246, 0.1)',
        lineColor: colors.blue['400'],
        title: `${!props.fromCoin ? chartTitle(props.primaryMetric) : ''}`,
        lineWidth: 2,
        priceFormat: {
          type: parseFloat(correctValue) > 1000000 ? 'volume' : 'price',
          precision: precision,
          minMove: minMove
        }
      });
    if (apiData?.length > 0) {
      priceLineSeries.setData(
        apiData.map(point => {
          return {
            time: Math.round(
              (new Date(point.datetime).getTime() + (DateTime.local().offset / 60) * 60 * 60 * 1000) / 1000
            ),
            value: parseFloat(point[props.primaryMetric])
          };
        })
      );
      chart.applyOptions({
        rightPriceScale: {
          visible: true
        }
      });
    }
  }
  if (from) {
    lastTimeScanned.value = from;
  }

  metricSeries =
    metricSeries ||
    chart.addLineSeries({
      color: colors.amber['400'],
      priceScaleId: 'left',
      title: `${!props.fromCoin && selectedTag.value !== 'none' ? chartTitle(selectedTag.value) : ''}`,
      lineWidth: 2,
      visible: selectedTag.value
    });
  metricSeries.setData(
    selectedTag.value && selectedTag.value != 'none'
      ? selectedTag.value == 'tvl'
        ? filteredTvlData.value
        : apiData.map(point => {
            return {
              time:
                Math.round(new Date(point.datetime).getTime() + (DateTime.local().offset / 60) * 60 * 60 * 1000) / 1000,
              value: point[selectedTag.value] * (selectedTag.value.includes('dominance') ? 100 : 1)
            };
          })
      : []
  );
  chart.applyOptions({
    leftPriceScale: {
      visible: selectedTag.value !== 'none'
    },
    timeScale: {
      timeVisible: !dayTimeFrame.value
    }
  });

  metricSeries.applyOptions({
    visible: selectedTag.value !== 'none',
    priceFormat: {
      type: {
        hourly_sentiment: 'price',
        daily_sentiment: 'price',
        tweet_volume: 'volume',
        trading_volume: 'volume',
        market_cap: 'volume',
        tweet_volume_dominance: 'percent',
        trading_volume_dominance: 'percent',
        tvl: 'volume'
      }[selectedTag.value]
    }
  });

  if (selectedTag.value == 'price_btc') {
    let correctValue = apiData?.[0]?.[selectedTag.value] ?? apiData?.[apiData?.length - 1]?.[selectedTag.value] ?? null;
    let btcPrecision = 2,
      btcMinMove = 0.01;

    if (correctValue) {
      btcPrecision = getNumberPrecision(correctValue);
      btcMinMove = getMinMove(btcPrecision);
    }
    metricSeries.applyOptions({
      visible: selectedTag.value == 'price_btc',
      priceFormat: {
        type: 'price',
        precision: btcPrecision,
        minMove: btcMinMove
      }
    });
  }
}

function chartTitle(title) {
  if (title == 'market_cap') {
    return 'MCap';
  } else if (title == 'daily_sentiment') {
    return 'Sentiment';
  } else if (title == 'trading_volume_dominance') {
    return 'Dominance';
  } else if (title == 'tweet_volume') {
    return 'Tweet Vol.';
  } else if (title == 'tvl') {
    return 'TVL';
  } else if (title == 'trading_volume') {
    return 'Trade Vol.';
  }
}

async function coinHistory(coin, start, end) {
  try {
    loading.value = true;
    const items = [
      props.primaryMetric,
      ...props.metrics.map(tag => {
        return tag.id;
      })
    ];
    const res = await $http.post('/data/coins_historical_grouped', {
      coin_uids: coin,
      start_datetime: start == '-1' ? start : new Date(start.getTime()),
      end_datetime: new Date(end.getTime()),
      items: items.join(','),
      frequency: '5min',
      timeframe: selectedTimeframe.value
    });
    return res.data.data;
  } finally {
    loading.value = false;
  }
}

function handleIndicatorAction(r) {
  selectedTag.value = r == 'none' ? 'none' : r;
  if (props.metricSelected == null) localStorage.setItem('selectedTag', r);
  else emits('update-metric', selectedTag.value);
}
function handleAction(r) {
  selectedTimeframe.value = r;
  if (!props.timeSelected) localStorage.setItem('selectedTimeframe', r);
  else emits('update-timeframe', selectedTimeframe.value);
  apiData = [];
  lastTimeScanned.value = new Date();
  const timeframe = timeframes.find(f => {
    return f.id === selectedTimeframe.value;
  });
  if (priceLineSeries) {
    chart.removeSeries(priceLineSeries);
  }
  if (metricSeries) {
    chart.removeSeries(metricSeries);
  }
  priceLineSeries = undefined;
  metricSeries = undefined;

  let startDate = timeframe?.time ? new Date(new Date().getTime() - timeframe.time) : -1;
  refreshData(startDate, new Date()).then(() => {
    chart.timeScale().fitContent();
  });
}
//MODAL
function exchangeModel() {
  if (mode.value == 'icon-line-chart') {
    showExchangeModal.value = false;
  } else if (mode.value == 'icon-candle-chart') {
    showExchangeModal.value = true;
  }
}

const exchangeTimeframes = computed(() => {
  return exchangesById.value[exchange.value?.exchange]?.[0]?.timeframes?.split(',') || [];
});

const updatedChartLink = computed(() => {
  return exchange.value && supportedSymbol.value && mode.value == 'icon-candle-chart'
    ? `/charts/price?symbol=CCXT:${supportedSymbol.value}:exchange:${exchange.value.exchange}`
    : props.expandChartLink;
});
const filteredTimeFrames = computed(() => {
  let filtered = selectedTag.value == 'tvl' ? timeframes.filter(i => !['1H', '3H', '6H'].includes(i.id)) : timeframes;
  if (props.fromCoin) {
    filtered = exchange.value ? filtered.filter(i => exchangeTimeframes.value.includes(i.frequency)) : filtered;
    if (mode.value == 'icon-candle-chart') {
      let isAllTimeAvailable = filtered.some(item => item.id === 'All Time');
      if (isAllTimeAvailable) {
        filtered = timeframes.filter(item => item.id != 'All Time');
      }
    }
  }
  filtered = filtered.filter(i => !['5m', '15m', '30m'].includes(i.id));
  return filtered;
});
const timeframeSelected = computed(() => {
  const defaultTimeFrame = props.forWidget ? filteredTimeFrames.value.find(x => x.id === '30D') : {};
  return (
    filteredTimeFrames.value.find(metric => metric.id === selectedTimeframe.value) || defaultTimeFrame || 'Indicators'
  );
});
const filteredMetrics = computed(() => {
  let metrics = [
    { id: 'none', label: 'None', action: 'none' },
    ...props.metrics
      ?.filter(i => (['1H', '3H', '6H'].includes(selectedTimeframe.value) ? i?.id != 'tvl' : i))
      .map(metric => {
        return {
          ...metric,
          label: metric.title,
          action: metric.id
        };
      })
      .sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      })
  ];
  if (props.candleChartMode) {
    metrics = metrics.filter(metric => metric.id !== 'none');
  }
  return metrics;
});
watch([timeframeSelected, mode], (newval, oldval) => {
  if (mode.value == 'icon-candle-chart' && selectedTimeframe.value == 'All Time') {
    selectedTimeframe.value = '30D';
  }
});

//TVL PROTOCOL INFO
const tvlData = ref([]);
const timeSlots = {
  '5m': 0,
  '15m': 0,
  '30m': 0,
  '1H': 0,
  '3H': 0,
  '6H': 0,
  '1D': 1,
  '7D': 7,
  '30D': 30,
  '90D': 90,
  '6M': 180,
  '1Y': 365
};
watch(tvlData, () => {
  if (apiData) {
    refreshData();
  }
});
const filteredTvlData = computed(() => {
  return tvlData.value
    .map(point => {
      return {
        time: Math.round(point.date * 1000 + (DateTime.local().offset / 60) * 60 * 60 * 1000) / 1000,
        value: point.totalLiquidityUSD
      };
    })
    .slice(tvlData.value.length - timeSlots[selectedTimeframe.value], tvlData.value.length);
});
async function getProtocolInfo() {
  let uid =
    props.selectedCategory?.type == 'subcategory'
      ? props.selectedCategory?.label?.toLowerCase()?.replace(/ /g, '')?.split('ecosystem')[0]
      : props.fromCoin
      ? props.coinUids[0]
      : '';
  let tvl = props.metrics?.find(i => i.id === 'tvl');
  let response = [];
  const coin = $store.getters.coins.find(coin => {
    if (uid == coin.name?.toLowerCase() || uid == coin.uid?.toLowerCase()) {
      return coin;
    }
  });
  if (tvl && coin) {
    response = await $http.get('/data/coin_protocol_info', {
      params: { coin_uid: props.primaryMetric == 'price' ? props.coinUids[0] : coin?.coin_uid }
    });
    tvlData.value = response.data;
  }
}
const exchangePairs = ref([]);
async function getTopPairs() {
  exchangePairs.value = await $ccxtApi.getTopPairs(props.coinUids[0]);
}
watch(
  () => props.coinUids,
  (newVal, oldVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      if (props.coinUids.length == 1 && priceLineSeries) {
        const timeframe = timeframes.find(f => {
          return f.id === selectedTimeframe.value;
        });
        let time =
          (Math.floor(new Date().getTime() / timeframe.frequency_time) * timeframe.frequency_time +
            (DateTime.local().offset / 60) * 60 * 60 * 1000) /
          1000;
        if (['30D', '90D', '6M', '1Y'].includes(timeframe.id)) {
          time -= 5 * 60; // subtract 5 minutes due to 23:55 logic data for one day
        }
        if (apiData?.length) {
          let last_data_time = Math.round(
            (new Date(apiData ? apiData.at(-1)?.datetime : null).getTime() +
              (DateTime.local().offset / 60) * 60 * 60 * 1000) /
              1000
          );
          const index = $store.state.data.coins.findIndex(x => x.uid == props.coinUids[0]);
          let price = $store.state.data.coins[index].price;
          if (last_data_time < time) {
            if (mode.value == 'icon-line-chart') {
              priceLineSeries.update({ time: time, value: price });
            }
            let startDate = timeframe?.time ? new Date(new Date().getTime() - timeframe.time) : -1;
            refreshFromAPI(startDate, new Date(), mode.value == 'icon-line-chart').then(() => {
              if (mode.value == 'icon-line-chart') {
                chart.timeScale().fitContent();
              }
            });
          } else {
            if (mode.value == 'icon-line-chart') {
              priceLineSeries.update({ time: last_data_time, value: price });
            }
          }
        }
      }
    }
  }
);

const updatedPairs = computed(() => {
  return exchangePairs.value.map(i => {
    return {
      ...i,
      url: exchangesById.value[i.exchange]?.[0]?.logo_url
    };
  });
});

const loader = computed(() => {
  return $store.getters.coinsLoader;
});
watch(loader, () => {
  if (!loader.value) {
    getProtocolInfo();
  }
});
onMounted(() => {
  nextTick(() => {
    chart.timeScale().fitContent();
    if (props.metrics.find(i => i.id == 'tvl')) {
      getProtocolInfo();
    }
  });
});

//RESIZE
function updateSize() {
  const elm = document.getElementById(id);

  if (!elm) {
    return;
  }
  chart.applyOptions({
    width: elm.clientWidth,
    height: elm.clientHeight
  });
}
onMounted(() => {
  const elm = document.getElementById(id);
  // most mondern browsers have this, but check just in case
  if (ResizeObserver && elm) {
    new ResizeObserver(() => {
      updateSize();
    }).observe(elm);
  }
});
onBeforeUnmount(() => {
  clearInterval(interval.value);
});
</script>
